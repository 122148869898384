.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff; /* Set the background color */
  z-index: 9999;
}

.preloader__logo {
  width: 100px; /* Adjust size of logo */
  animation: zoomInOut 2s ease-in-out infinite; /* Apply zoom animation */
}

@keyframes zoomInOut {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(2.2); /* Zoom in */
  }
  100% {
    transform: scale(0.5); /* Zoom out */
  }
}
